import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
  colorTwo?: string;
}

const InfoIcon: FC<Props> = ({ width, height, color, colorTwo }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "15"}
      height={height || "14"}
      viewBox="0 0 15 14"
      fill="none"
    >
      <circle
        cx="7.625"
        cy="7"
        r="7"
        fill={color || "#ACACAC"}
        fillOpacity="0.45"
      />
      <path
        d="M7.05912 11V5.32425H8.63326V11H7.05912ZM7.84988 4.59261C7.61586 4.59261 7.41509 4.51501 7.24758 4.35982C7.08253 4.20216 7 4.0137 7 3.79446C7 3.57768 7.08253 3.39169 7.24758 3.23649C7.41509 3.07883 7.61586 3 7.84988 3C8.08391 3 8.28345 3.07883 8.4485 3.23649C8.61601 3.39169 8.69977 3.57768 8.69977 3.79446C8.69977 4.0137 8.61601 4.20216 8.4485 4.35982C8.28345 4.51501 8.08391 4.59261 7.84988 4.59261Z"
        fill={colorTwo || "#5C5C5C"}
      />
    </svg>
  );
};

export default InfoIcon;
