import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const PlayIcon: FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "12"}
      height={height || "13"}
      viewBox="0 0 12 13"
      fill="none"
    >
      <circle cx="6" cy="6.5" r="6" fill={color || "#FA4865"} />
      <path
        d="M9.71484 6.50005L4.14342 9.71672L4.14342 3.28339L9.71484 6.50005Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayIcon;
