/** @jsxImportSource @emotion/react */

import { ResponsiveContext } from "lib/context";
import {
  getDescriptionForCode,
  getSourceForCode,
  getSourcesTextForCode,
  isExploratoryScore,
} from "lib/helpers/exploratoryHelpers";
import { getCountyName, getMetroName, getStateName } from "lib/helpers/getArea";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { useContext, useMemo } from "react";
import { Geo } from "types/MapContext";
import { Datapoints } from "types/cube";
import { ExploratorySources } from "types/exploratory";
import { Flex } from "./Flex";

export const GraphContext = ({
  exploratory,
  context,
  geo,
  activeMonth,
  plotData,
  latestMonth,
  fullName,
}) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const range = useMemo(() => {
    if (plotData.length > 0) {
      const isScore = isExploratoryScore(exploratory);
      const date = plotData[plotData.length - 1].date;
      const year = moment(date).format("YYYY");
      let month = moment(date).format("MMM");

      if (isScore) {
        return `${month}. ${year}`;
      } else if (
        exploratory === Datapoints.POPULATION_GROWTH_RATE ||
        exploratory === Datapoints.MEDIAN_INCOME_GROWTH_RATE ||
        exploratory === Datapoints.HOUSING_UNIT_GROWTH
      ) {
        return `${parseInt(year) - 5} - ${year}`;
      } else if (exploratory === Datapoints.HOME_VALUE_GROWTH_RATE) {
        const curMonth = moment.monthsShort(activeMonth - 1);
        return `${curMonth}. ${parseInt(year) - 1} - ${month}. ${year}`;
      } else if (
        exploratory === Datapoints.MOM_HOME_VALUE_GROWTH ||
        exploratory === Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE ||
        exploratory === Datapoints.PERCENT_CRASH_GREAT_RECESSION ||
        exploratory === Datapoints.PER_CHANGE_HOME_VALUE_JUNE
      ) {
        return "";
      } else if (exploratory === Datapoints.PERCENT_CRASH_GREAT_RECESSION) {
        return "2007 - 2012";
      } else if (exploratory === Datapoints.PER_CHANGE_HOME_VALUE_JUNE) {
        return `Jun. 2022 - ${month}. ${year}`;
      } else if (activeMonth || exploratory === Datapoints.RENT_GROWTH_YOY) {
        month = activeMonth ? moment.monthsShort(activeMonth - 1) : month;
        return `${month}. ${year}`;
      } else return `${year}`;
    } else return "";
  }, [exploratory, plotData, activeMonth]);

  if (!context) {
    return null;
  }

  function mayContainEstimates() {
    if (exploratory.includes("rent")) {
      return true;
    }
    if (
      ["zip", "county"].includes(geo) &&
      [
        "median_household_income",
        "buy_versus_rent_percentage",
        "cap_rate",
        "home_value_growth_rate",
        "home_value_five_year_growth_rate",
        "home_value_to_earnings_ratio",
        "home_value_to_med_hh_income_ratio",
        "house_payment_rate_of_med_income",
        "median_income_growth_rate",
        "mom_home_value_growth",
        "over_under_valued_percentage",
        "percent_crash_great_recession",
        "typical_house_payment",
      ].includes(exploratory)
    ) {
      return true;
    }
    return false;
  }

  const metro =
    geo === Geo.ZIP || geo === Geo.COUNTY
      ? getMetroName(context, geo, { solo: true })
      : geo === Geo.METRO
      ? fullName?.length > 10
        ? context.BASENAME || fullName.split(",")[0]
        : fullName || ""
      : "";

  return (
    <div>
      <Flex
        direction={"row"}
        css={(theme) => ({
          marginTop: isTabletOrMobile ? 20 : 0,
          fontSize: isTabletOrMobile
            ? theme.fontSizes.small
            : theme.fontSizes.default,
        })}
      >
        <ul
          css={(theme) => ({
            paddingLeft: theme.padding.medium,
            textAlign: "left",
            listStyle: "none",
          })}
        >
          {getSourcesTextForCode(exploratory) && (
            <li style={{ whiteSpace: "nowrap" }}>
              <span
                css={() => ({
                  fontWeight: "bold",
                  marginRight: "10px",
                })}
              >
                Source:
              </span>{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: getSourcesTextForCode(exploratory, true) as string,
                }}
              />
            </li>
          )}
          {getDescriptionForCode(exploratory) && (
            <li>{getDescriptionForCode(exploratory)}</li>
          )}

          {[ExploratorySources.ZILLOW, ExploratorySources.REALTOR].includes(
            getSourceForCode(exploratory) as ExploratorySources,
          ) &&
            latestMonth && (
              <li style={{ whiteSpace: "nowrap" }}>
                <span
                  css={() => ({
                    fontWeight: "bold",
                    marginRight: "10px",
                  })}
                >
                  Next Update:
                </span>
                {getSourceForCode(exploratory) === ExploratorySources.ZILLOW
                  ? "Mid"
                  : "Early"}
                -{moment.monthsShort(latestMonth + 1)}
              </li>
            )}

          {range && (
            <li style={{ whiteSpace: "nowrap" }}>
              <span
                css={() => ({
                  fontWeight: "bold",
                  marginRight: "10px",
                })}
              >
                Data Info:
              </span>
              {range}
            </li>
          )}
        </ul>
        <ul
          css={(theme) => ({
            paddingLeft: theme.padding.medium,
            textAlign: "left",
            listStyle: "none",
          })}
        >
          {geo === Geo.ZIP && (
            <li>
              <span
                css={() => ({
                  fontWeight: "bold",
                  marginRight: "10px",
                })}
              >
                {capitalize(geo)}:
              </span>
              {context.GEOID}
            </li>
          )}
          {[Geo.ZIP, Geo.COUNTY].includes(geo) &&
            (getCountyName(context.GEOID) || context.NAME.split(",")[0]) && (
              <li style={{ whiteSpace: "nowrap" }}>
                <span
                  css={() => ({
                    fontWeight: "bold",
                    marginRight: "10px",
                  })}
                >
                  County:
                </span>
                {geo === Geo.ZIP
                  ? getCountyName(context.GEOID)
                  : context.NAME.split(",")[0]}
              </li>
            )}
          {metro && (
            <li style={{ whiteSpace: "nowrap" }}>
              <span
                css={() => ({
                  fontWeight: "bold",
                  marginRight: "10px",
                })}
              >
                Metro:
              </span>
              {metro}
            </li>
          )}
          {geo !== Geo.STATE && (
            <li style={{ whiteSpace: "nowrap" }}>
              <span
                css={() => ({
                  fontWeight: "bold",
                  marginRight: "10px",
                })}
              >
                State:
              </span>
              {getStateName(context)}
            </li>
          )}
        </ul>
      </Flex>
      {mayContainEstimates() && (
        <p
          css={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            textAlign: "left",
            fontSize: "0.75rem",
          }}
        >
          Some of the data may be estimated using data from the US Census and
          Zillow.
        </p>
      )}
    </div>
  );
};
