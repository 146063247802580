/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { doc, updateDoc } from "firebase/firestore";
import useGeoExploratory, {
  ExploratoryOption,
  GroupedExploratoryOption,
} from "hooks/useGeoExploratory";
import { useUserData } from "hooks/useUser";
import { ResponsiveContext } from "lib/context";
import { db } from "lib/firebase";
import { slugify } from "lib/helpers";
import {
  getExploratoryVideoCode,
  getNameForCode,
  getShortSummaryForCode,
  getSummaryForCode,
  isExploratoryScore,
} from "lib/helpers/exploratoryHelpers";
import { getNameForScoreCode } from "lib/helpers/scoreHelpers";
import { hasAccessToPremiumArea } from "lib/helpers/userHelpers";
import { ExploratoryType } from "lib/options/exploratory";
import Image from "next/image";
import { event } from "nextjs-google-analytics";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Geo } from "types/MapContext";
import { CombinedDatapoints, Datapoints, ScoreDatapoints } from "types/cube";
import { Flex } from "./Flex";
import SelectDropdownIndicator from "./SelectDropdownIndicator";
import { SelectGroupLabel } from "./SelectGroupLabel";
import { Text } from "./Text";
import PlayIcon from "./icons/PlayIcon";
import { PlanModalContext } from "./provider/PlanModalProvider";
import ExploratoryTooltip from "./tooltips/ExploratoryTooltip";
import useScreenSize from "lib/helpers/useScreenSize";
import MapExpTooltip from "./MapExpTooltip";

export const MapExploratoryInput = ({
  exploratory,
  geo,
  hideLabel,
  downloadButton,
  overrideOptions = [],
  zipCode,
  info,
  handleExploratoryChange,
}: {
  exploratory: CombinedDatapoints;
  geo: Geo;
  hideLabel: boolean;
  overrideOptions: GroupedExploratoryOption[];
  zipCode: string;
  info: any;
  downloadButton: any;
  handleExploratoryChange: (
    val: CombinedDatapoints,
    updateExploratory?: boolean,
  ) => boolean;
}) => {
  const screenSize = useScreenSize();
  const dropdownItems = overrideOptions
    .filter((elem) => {
      if (
        !elem.label.includes("Long-Term Growth") &&
        !elem.label.includes("Investor Forecast")
      ) {
        return true;
      } else {
        return false;
      }
    })
    .map((elem) => {
      const element = {
        label: elem.label,
        options: elem.options.filter(
          (elem) =>
            !elem.label.includes("Long-Term Growth") &&
            !elem.label.includes("Investor Forecast"),
        ),
      };
      return element;
    });

  const theme = useTheme();
  const user = useUserData();
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const [activeGroups, setActiveGroups] = useState<any[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const { groupedExploratoriesWithScoreData } = useGeoExploratory(geo);
  const isScore = isExploratoryScore(exploratory);
  const userCanAccess =
    user.canAccessPremium ||
    hasAccessToPremiumArea(
      user,
      { state_code: info.state_code, geo_code: info.geo_code },
      false,
      geo,
    );

  const allExploratorySelectOptions = useMemo(() => {
    return groupedExploratoriesWithScoreData.map((data) => {
      const updatedOptions = data.options.filter((option) => {
        if (geo === "zip") {
          if (overrideOptions?.length) {
            return (
              (option.value as string) !==
                Datapoints.PERCENT_CRASH_GREAT_RECESSION ||
              (option.value as string) !== Datapoints.TOTAL_MIGRATION ||
              (option.value as string) !== Datapoints.MIGRATION_PERCENTAGE
            );
          }
          return (
            option.value !== Datapoints.PERCENT_CRASH_GREAT_RECESSION &&
            option.value !== Datapoints.OVER_UNDER_VALUED_PERCENTAGE
          );
        }

        return option.value !== Datapoints.RENT_GROWTH_YOY;
      });

      return {
        label: data.label,
        options: updatedOptions,
      };
    });
  }, [geo, groupedExploratoriesWithScoreData, overrideOptions?.length]);

  useEffect(() => {
    if (isTyping) {
      setActiveGroups(["Real Estate", "Demographic", "Premium"]);
    } else {
      setActiveGroups([]);
    }
  }, [isTyping]);

  const userData = useUserData();
  const [openOnboardingTooltip, setOpenOnboardingTooltip] = useState(false);

  useEffect(() => {
    if (!userData?.isLoading) {
      const haveSeenGraphTooltip = localStorage.getItem("haveSeenGraphTooltip");
      if (!haveSeenGraphTooltip && !userData?.haveSeenGraphTooltip) {
        setOpenOnboardingTooltip(true);
        localStorage.setItem("haveSeenGraphTooltip", "true");
        if (userData.uid) {
          updateDoc(doc(db, "users", userData.uid), {
            haveSeenGraphTooltip: true,
          });
        }
      }
    }
  }, [userData?.isLoading]);

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      gap={theme.gap[4]}
      css={{ minWidth: "100%" }}
    >
      <Flex
        css={{
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          padding: " 0 20px",
        }}
      >
        <Text
          fontSize={isTabletOrMobile ? "large" : "heading2"}
          align="center"
          css={(theme) => ({
            fontSize: isTabletOrMobile
              ? theme.fontSizes.large
              : theme.fontSizes.larger,
            textShadow: theme.textShadow,
            textOverflow: "ellipsis",
            maxWidth: "90%",
            lineHeight: "24px",
            flex: 2,
            paddingLeft:
              screenSize.width >= 1024 && screenSize.width <= 1440
                ? "40px"
                : "60px",
          })}
        >
          {getNameForCode(exploratory) ||
            getNameForScoreCode(exploratory as ScoreDatapoints)}
        </Text>
        <div>{downloadButton}</div>
      </Flex>
      <Flex justify="center" direction="column">
        <Text
          css={(theme) => ({
            width: isTabletOrMobile ? "90%" : "80%",
            textAlign: "center",
            margin: "0 auto",
            textOverflow: "ellipsis",
            color: theme.colors.grayText,
          })}
        >
          {isTabletOrMobile
            ? getShortSummaryForCode(exploratory) ||
              getSummaryForCode(exploratory)
            : getSummaryForCode(exploratory)}
          {!isTabletOrMobile && isScore && (
            <p css={{ fontSize: theme.fontSizes.smaller, margin: 0 }}>
              Note: this score is a probabilistic projection of where home
              prices could head in the next 12 months, but is not a guarantee
              and could be subject to error.
            </p>
          )}
        </Text>
      </Flex>

      <MapExpTooltip
        tooltipText={`Change Data Point to explore Inventory Trends in the ${zipCode}`}
        onClose={() => setOpenOnboardingTooltip(false)}
        open={openOnboardingTooltip}
        position={screenSize.width < 1920 ? "bottom" : "topRight"}
      >
        <Select
          value={{
            value: exploratory,
            label:
              getNameForCode(exploratory) ||
              getNameForScoreCode(exploratory as ScoreDatapoints),
            category: "",
          }}
          isSearchable={!isTabletOrMobile}
          components={{
            DropdownIndicator: SelectDropdownIndicator,
            GroupHeading: (props) => (
              <SelectGroupLabel
                {...props}
                hideLabel={overrideOptions?.length ? true : false}
                activeGroups={activeGroups}
                handleClick={(groupLabel) => {
                  const updatedActiveGroups = activeGroups.includes(groupLabel)
                    ? activeGroups.filter((group) => group !== groupLabel)
                    : [...activeGroups, groupLabel].filter(
                        (value, index, array) => array.indexOf(value) === index,
                      );
                  setActiveGroups(updatedActiveGroups);
                }}
                allSelectOptions={allExploratorySelectOptions}
              />
            ),
          }}
          maxMenuHeight={400}
          onInputChange={(val) => {
            if (val) {
              setIsTyping(true);
            } else {
              setIsTyping(false);
            }
          }}
          onChange={(selectedOption: ExploratoryOption | null) => {
            if (!!selectedOption) {
              event(
                `map-control-data-point-${slugify(
                  selectedOption?.label as string,
                )}`,
                {
                  category: "Map Control",
                  label: `Data Point, ${selectedOption?.label as string} (${
                    (selectedOption as any).category
                  })`,
                },
              );
              if (selectedOption?.isComingSoon) {
                return;
              }
              if (selectedOption?.premium && !user.isPremiumOrBasic) {
                setPlanPopupTrigger(
                  `Change Datapoint Graphview: ${selectedOption.value}`,
                );
                setShowPlanModal(true);
                return;
              }
              localStorage.setItem("radio", selectedOption?.value);
              localStorage.setItem("category", selectedOption?.category);
              const updated = handleExploratoryChange(
                selectedOption?.value,
                selectedOption?.type === ExploratoryType.EXPLORATORY ||
                  (selectedOption?.type === ExploratoryType.SCORE &&
                    [
                      ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE,
                      ScoreDatapoints.HOME_BUYER_SCORE,
                      ScoreDatapoints.INVESTOR_SCORE,
                    ].includes(selectedOption.value as ScoreDatapoints) &&
                    userCanAccess),
              );
              if (!updated) {
                setPlanPopupTrigger(
                  `Change Datapoint Graphview: ${selectedOption.value}`,
                );
                setShowPlanModal(true);
              }
            }
          }}
          formatOptionLabel={(option: any) => {
            return (
              <Flex justify="space-between" align="center" gap={4}>
                <Text>{option.label}</Text>
                <Flex>
                  {option.isComingSoon && (
                    <span
                      css={{
                        color: theme.colors.white,
                        fontSize: theme.fontSizes.smaller,
                        background: theme.colors.primary,
                        borderRadius: theme.radii.large,
                        width: 80,
                        textAlign: "center",
                      }}
                    >
                      Coming Soon
                    </span>
                  )}
                  {option.premium && !user.isPremiumOrBasic && (
                    <Image
                      width={15}
                      height={15}
                      src={"/premium-icon.svg"}
                      alt="Premium"
                    />
                  )}
                </Flex>
              </Flex>
            );
          }}
          options={dropdownItems}
          onMenuOpen={() => {
            // needs to be at end of callback queue
            setTimeout(() => {
              const exploratoryList = groupedExploratoriesWithScoreData.flatMap(
                (group) => {
                  return group.options;
                },
              );
              const matchedIndex = exploratoryList.findIndex((element) => {
                if (element.value === exploratory) {
                  return true;
                }

                return false;
              });

              if (matchedIndex !== -1) {
                const optionElems = document.querySelectorAll(`[id*="option"]`);

                if (optionElems?.length) {
                  const selectedElem = optionElems[matchedIndex];
                  selectedElem?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start",
                  });
                }
              }
            }, 15);
          }}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: theme.radii.small,
              borderWidth: "1px",
              borderColor: theme.colors.inputBorder,
              fontSize: theme.fontSizes.default,
              width: 330,
              maxWidth: "90%",
              margin: "auto",
              boxShadow: theme.shadow,
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              padding: "5px 12px",
              fontSize: "14.5px",
              paddingLeft: "16px",
              "&:hover": {
                fontSize: "14.5px",
              },
            }),
            menu: (baseStyles) => {
              return {
                ...baseStyles,
                zIndex: 1000,
              };
            },
          }}
        />
      </MapExpTooltip>

      <Flex
        direction="column"
        align="center"
        gap={theme.gap[6]}
        style={{ width: "330px", display: "flex", justifyContent: "center" }}
      >
        <ExploratoryTooltip
          placement="bottom"
          title={
            getNameForCode(exploratory) ||
            getNameForScoreCode(exploratory as ScoreDatapoints) ||
            ""
          }
          ytVideoCode={getExploratoryVideoCode(exploratory)}
          value={exploratory}
        >
          <Flex align="center" gap={theme.gap[1]} css={{ cursor: "pointer" }}>
            <PlayIcon />
            <span
              css={{
                textDecoration: "underline",
              }}
            >
              Watch video to learn about this data point
            </span>
          </Flex>
        </ExploratoryTooltip>
      </Flex>
    </Flex>
  );
};
