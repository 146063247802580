import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
const MapExpTooltip = ({
  open,
  children,
  tooltipText,
  position = "topRight",
  onClose,
}) => {
  const theme = useTheme();
  const tooltipContainerStyle = {
    position: "relative",
    display: "inline-block",
  };

  const getTooltipStyle = () => {
    const baseStyle = {
      minWidth: 260,
      maxWidth: 260,
      position: "absolute",
      backgroundColor: theme.colors.primary,
      color: "white",
      padding: "16px",
      borderRadius: theme.radii.medium,
      zIndex: 1080,
      display: open ? "block" : "none",
    };

    if (position === "bottom") {
      return {
        ...baseStyle,
        top: "102%",
        left: "50%",
        transform: "translateX(-50%)",
        marginTop: "8px",
      };
    } else if (position === "topRight") {
      return {
        ...baseStyle,
        top: "-25%",
        right: "-10px",
        transform: "translateX(100%)",
        marginTop: "8px",
      };
    }
    return baseStyle;
  };

  const getTooltipArrowStyle = () => {
    const baseArrowStyle = {
      position: "absolute",
      width: "0",
      height: "0",
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
    };

    if (position === "bottom") {
      return {
        ...baseArrowStyle,
        top: "-8px",
        left: "50%",
        transform: "translateX(-50%)",
        borderBottom: `8px solid ${theme.colors.primary}`,
      };
    } else if (position === "topRight") {
      return {
        ...baseArrowStyle,
        top: "18%",
        left: "-16px",
        transform: "translateY(-50%)",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
        borderRight: `8px solid ${theme.colors.primary}`,
      };
    }
    return baseArrowStyle;
  };

  return (
    <div style={tooltipContainerStyle as any}>
      {children}
      <div style={getTooltipStyle() as any}>
        <div style={getTooltipArrowStyle() as any}></div>
        <div
          style={{
            fontSize: theme.fontSizes.medium,
            fontWeight: 700,
            textTransform: "inherit",
          }}
        >
          {tooltipText}
        </div>
        <div onClick={onClose} css={{ position: "absolute", right: 8, top: 8 }}>
          <Icon path={mdiClose} size="20px" css={{ cursor: "pointer" }} />
        </div>
      </div>
    </div>
  );
};

export default MapExpTooltip;
