import {useTheme} from "@emotion/react";
import {Flex} from "components/Flex";
import {CustomTooltip} from "components/Tooltip";
import {PlanModalContext} from "components/provider/PlanModalProvider";
import {useUserData} from "hooks/useUser";
import {useRouter} from "next/router";
import React, {Dispatch, SetStateAction, useContext} from "react";
import {CheckboxInput} from "../../common/CheckboxInput";

interface Props {
    title: string;
    showSecondaryLine: boolean;
    setShowSecondaryLine: Dispatch<SetStateAction<boolean>>;
}

const SecondaryLineSwitch = ({showSecondaryLine, setShowSecondaryLine, title}: Props) => {
    const {setShowPlanModal, setPlanPopupTrigger} =
        useContext(PlanModalContext);
    const user = useUserData();
    const router = useRouter();
    const theme = useTheme();

    return (
        <CustomTooltip
            title="To use option, upgrade to premium plan"
            overlayInnerStyle={{width: 200, borderRadius: theme.radii.medium}}
            open={user.isPremiumOrBasic ? false : undefined}
        >
            <Flex
                justify="space-between"
                align="center"
                css={(theme) => ({
                    float: "left",
                    position: "relative",
                    left: 15,
                    align: "left",
                    zIndex: 10,
                    fontSize: theme.fontSizes.default,
                    overflow: "hidden",
                })}
                onClick={() => {
                    if (!user.isPremiumOrBasic) {
                        setPlanPopupTrigger("Switch Graph To Monthly");
                        setShowPlanModal(true);
                    }
                }}
            >
              <span>
                <CheckboxInput
                    checked={showSecondaryLine}
                    onChange={() =>
                        user.isPremiumOrBasic && setShowSecondaryLine((prev) => !prev)
                    }
                    type="checkbox"
                    css={(theme) => ({
                        "&:checked": {
                            backgroundColor: theme.colors.black,
                            borderColor: theme.colors.black,
                        },
                        "&:checked::after": {
                            color: theme.colors.white,
                        },
                    })}
                />
              </span>
                <span css={(theme) => ({
                    fontSize: theme.fontSizes.small,
                    color: theme.colors.black,
                    position: "relative",
                    bottom: 3
                })}>
                    {title}
                </span>
            </Flex>
        </CustomTooltip>
    );
};

export default SecondaryLineSwitch;
