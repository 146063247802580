import { UilUser } from "@iconscout/react-unicons";
import { Avatar, Dropdown } from "antd";
import { ResponsiveContext } from "lib/context";
import Image from "next/image";
import { FC, ReactNode, useContext, useMemo } from "react";
import FileIcon from "../../public/file.svg";
import BookIcon from "../../public/book-icon.svg";
import HelpIcon from "../../public/help-circle.svg";
import SignOutIcon from "../../public/log-out.svg";
import PremiumIcon from "../../public/play-button.svg";
import SettingsIcon from "../../public/settings.svg";
import { Flex } from "../Flex";
import { useRouter } from "next/router";
import { UilEstate } from "@iconscout/react-unicons";

interface Props {
  photoURL?: string;
  onSignOut: () => void;
}

const MenuLabel = ({
  title,
  icon: Icon,
  svgIcon,
  isSignOut,
}: {
  title: string;
  icon?: any;
  svgIcon?: string;
  isSignOut?: boolean;
}) => {
  return (
    <Flex
      align="center"
      justify={isSignOut ? "center" : undefined}
      css={(theme) => ({
        gap: isSignOut ? 7 : 10,
        paddingLeft: isSignOut ? 0 : 2,
      })}
    >
      {svgIcon && <Image src={svgIcon} alt={title} css={{ margin: "0 2px" }} />}
      {Icon && <Icon />}
      <span
        css={(theme) => ({
          fontSize: 14,
          color: "#191919",
        })}
      >
        {title}
      </span>
    </Flex>
  );
};

const ProfileMenu: FC<Props> = ({ photoURL, onSignOut }) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const router = useRouter();

  const menuItems = [
    {
      label: <MenuLabel title="Home" icon={UilEstate} />,
      key: "home",
      onClick() {
        window.location.href = process.env
          .NEXT_PUBLIC_REVENTURE_APP_URL as string;
      },
    },
    {
      label: <MenuLabel title="Premium Content" svgIcon={PremiumIcon} />,
      key: "premium-content",
      onClick() {
        router.push("/profile/premiumContent", undefined, {
          shallow: true,
        });
      },
    },
    {
      label: <MenuLabel title="Settings" svgIcon={SettingsIcon} />,
      key: "settings",
      onClick() {
        router.push("/profile/settings", undefined, { shallow: true });
      },
    },
    {
      label: <MenuLabel title="Billing" svgIcon={FileIcon} />,
      key: "billings",
      onClick() {
        router.push("/profile/billings", undefined, { shallow: true });
      },
    },
    {
      label: <MenuLabel title="Data Glossary" svgIcon={BookIcon} />,
      key: "settings",
      onClick() {
        router.push("/profile/glossary", undefined, { shallow: true });
      },
    },
    {
      label: <MenuLabel title="Help" svgIcon={HelpIcon} />,
      key: "help",
      onClick() {
        router.push("/profile/help", undefined, { shallow: true });
      },
    },
    {
      label: <MenuLabel title="Sign out" svgIcon={SignOutIcon} isSignOut />,
      key: "sign-out",
      className: "profile-dropdown-sign-out-button",
      onClick: onSignOut,
    },
  ];

  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        className: `map-header-profile-dropdown ${
          isTabletOrMobile ? "mobile-map-header-profile-dropdown" : ""
        }`,
        items: menuItems,
      }}
    >
      <Avatar
        src={photoURL}
        icon={photoURL ? undefined : <UilUser />}
        css={(theme) => ({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: isTabletOrMobile ? 37 : 40,
          height: isTabletOrMobile ? 37 : 40,
          cursor: "pointer",
        })}
      />
    </Dropdown>
  );
};

export default ProfileMenu;
