import { useTheme } from "@emotion/react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { Flex } from "components/Flex";
import { ResponsiveContext } from "lib/context";
import {
  getSourcesTextForCode,
  getSummaryForCode,
} from "lib/helpers/exploratoryHelpers";
import { getSummaryForScoreCode } from "lib/helpers/scoreHelpers";
import { ReactNode, useContext } from "react";
import { CombinedDatapoints } from "types/cube";

interface Props {
  title: string;
  value: CombinedDatapoints;
  children: ReactNode;
  mobileDashboard?: boolean;
  placement?: TooltipPlacement;
  ytVideoCode?: string;
}

const ExploratoryTooltip = ({
  title,
  value,
  children,
  mobileDashboard,
  placement,
  ytVideoCode,
}: Props) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const theme = useTheme();

  let overlayClassName = "tooltip-exploratory";
  if (isTabletOrMobile)
    overlayClassName = "tooltip-exploratory-mobile " + overlayClassName;
  if (mobileDashboard)
    overlayClassName = "tooltip-exploratory-dashboard " + overlayClassName;

  return (
    <Tooltip
      placement={placement || isTabletOrMobile ? "bottom" : "right"}
      trigger={["click"]}
      overlayClassName={overlayClassName}
      destroyTooltipOnHide
      title={
        !isTabletOrMobile ? (
          <Flex align="center">
            {ytVideoCode && (
              <iframe
                width={202}
                height={360}
                src={`https://youtube.com/embed/${ytVideoCode}?feature=share`}
                css={{ border: "none", borderRadius: theme.radii.small }}
              />
            )}

            <div css={{ padding: "0 18px 0 30px" }}>
              <p
                css={{
                  marginTop: theme.margin.default,
                  fontSize: theme.fontSizes.medium,
                  fontWeight: theme.fontWeights.heading,
                }}
              >
                {title}
              </p>
              <p
                css={{
                  fontSize: theme.fontSizes.small,

                  "::first-letter": {
                    textTransform: "capitalize",
                  },
                }}
              >
                {getSummaryForCode(value) || getSummaryForScoreCode(value)}
              </p>
              {getSourcesTextForCode(value) && (
                <p
                  css={{
                    fontSize: theme.fontSizes.small,
                    color: theme.colors.primary,

                    a: {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Source:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getSourcesTextForCode(value) as string,
                    }}
                  />
                </p>
              )}
            </div>
          </Flex>
        ) : (
          <>
            {ytVideoCode && (
              <iframe
                width={"100%"}
                height={240}
                src={`https://youtube.com/embed/${ytVideoCode}?feature=share`}
                css={{ border: "none", borderRadius: theme.radii.small }}
              />
            )}
            <p
              css={{
                marginTop: theme.margin.default,
                fontSize: theme.fontSizes.medium,
                fontWeight: theme.fontWeights.heading,
              }}
            >
              {title}
            </p>
            <p
              css={{
                fontSize: theme.fontSizes.small,

                "::first-letter": {
                  textTransform: "capitalize",
                },
              }}
            >
              {getSummaryForCode(value)}
            </p>
            <p
              css={{
                fontSize: theme.fontSizes.small,
                color: theme.colors.primary,

                a: {
                  textDecoration: "underline",
                },
              }}
            >
              Source:{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: getSourcesTextForCode(value) as string,
                }}
              />
            </p>
          </>
        )
      }
    >
      {children}
    </Tooltip>
  );
};

export default ExploratoryTooltip;
