import { useTheme } from "@emotion/react";
import { MouseEventHandler, ReactNode } from "react";
import { Tooltip, TooltipProps } from "antd";
import { Text } from "./Text";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

export const CustomTooltip = ({
  title,
  color,
  content,
  showClose,
  onClose,
  ...rest
}: TooltipProps & {
  content?: ReactNode;
  showClose?: boolean;
  onClose?: MouseEventHandler<HTMLDivElement>;
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      overlayInnerStyle={{
        background: theme.colors.primary,
        width: 260,
        borderRadius: theme.radii.medium,
        padding: 16,
        color: "#FFF",
        ...(rest?.overlayInnerStyle || {}),
      }}
      arrowPointAtCenter
      destroyTooltipOnHide
      overlay={
        <div css={{ position: "relative" }}>
          {title && (
            <Text
              bolder
              css={{ lineHeight: "22px", fontSize: theme.fontSizes.medium }}
            >
              {title as ReactNode}
            </Text>
          )}
          {content}
          {showClose && (
            <div
              onClick={onClose}
              css={{ position: "absolute", right: -8, top: -8 }}
            >
              <Icon path={mdiClose} size="20px" css={{ cursor: "pointer" }} />
            </div>
          )}
        </div>
      }
      color={color || theme.colors.primary}
      {...rest}
    />
  );
};
