/** @jsxImportSource @emotion/react */

import * as Unicons from "@iconscout/react-unicons";
import { event } from "nextjs-google-analytics";
import { slugify } from "../lib/helpers";
import { Flex } from "./Flex";

export const SelectGroupLabel = ({
  data,
  handleClick,
  allSelectOptions,
  hideLabel,
  activeGroups = [] as any[],
}) => {
  const matchedSection = allSelectOptions.find(
    (selectGroup) => selectGroup.label === data.label,
  );
  const sectionIsOpen = activeGroups.includes(matchedSection?.label);
  return (
    <Flex
      align="center"
      justify="space-between"
      onClick={() => {
        event(`toggle-group-${slugify(data.label)}`, {
          category: "Toggle group",
          label: data.label,
        });
        handleClick(data.label);
      }}
      css={() => ({ padding: "5px", cursor: "pointer" })}
    >
      <span
        css={(theme) => ({
          fontSize: theme.fontSizes.default,
          fontWeight: theme.fontWeights.bold,
          color: theme.colors.red,
        })}
      >
        {data.label}
      </span>
      <span
        css={(theme) => ({
          backgroundColor: theme.colors.panel,
          borderRadius: theme.radii.small,
          color: theme.colors.navy,
          display: "inline-block",
          fontSize: theme.fontSizes.small,
          fontWeight: theme.fontWeights.body,
          lineHeight: "1",
          minWidth: 1,
          padding: "0.16em 0.5em",
          textAlign: "center",
        })}
      >
        {!hideLabel && (
          <Flex align="center">
            {sectionIsOpen ? (
              <Unicons.UilArrowDown />
            ) : (
              <Unicons.UilArrowRight />
            )}
            <span>
              {sectionIsOpen ? "Click to see less" : "Click to see more"}
            </span>
          </Flex>
        )}
      </span>

      {data.options.some((o) => o.isNew) && (
        <span
          css={(theme) => ({
            backgroundColor: theme.colors.primary,
            borderRadius: theme.radii.large,
            color: theme.colors.white,
            display: "inline-block",
            fontSize: theme.fontSizes.small,
            fontWeight: theme.fontWeights.body,
            lineHeight: "1",
            minWidth: 1,
            padding: "0.16em 0.5em",
            textAlign: "center",
          })}
        >
          <span
            css={(theme) => ({
              fontSize: theme.fontSizes.small,
              textAlign: "end",
            })}
          >
            New
          </span>
        </span>
      )}
    </Flex>
  );
};
