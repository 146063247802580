import Image from "next/image";
import arrowUpBlack from "public/arrow-up-black.svg";
import { components } from "react-select";

const SelectDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Image
        width={15}
        height={15}
        src={arrowUpBlack}
        alt="Toggle Panel"
        css={(theme) => ({
          transform: `rotate(180deg)`,
          transition: "transform 0.3s ease",
        })}
      />
    </components.DropdownIndicator>
  );
};

export default SelectDropdownIndicator;
