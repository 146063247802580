import { stateMap } from "lib/constants";
import { countyList } from "lib/options/countyList";
import { metroMap } from "lib/options/metroMap";
import { Geo } from "types/MapContext";
import { findStateFromZipCode } from "./geoSearchHelpers";

export const createGraphData = (geo: Geo, GEOID?: string) => {
  if (!GEOID) return {};

  const feature: any = {
    geo,
  };
  switch (geo) {
    case Geo.ZIP:
      const foundState = findStateFromZipCode(GEOID);
      return {
        GEOID,
        NAME: GEOID,
        name: GEOID,
        STATE: foundState?.label,
        state: foundState?.value,
        stateAbbr: foundState?.abbreviation,
        ...feature,
      };
    case Geo.COUNTY:
      const county = countyList.find((county) => county.value == GEOID);
      const countyState = stateMap.find(
        (state) => state?.["stateCode"] == county?.stateCode,
      );
      feature.name = county?.label;
      feature.STATE = countyState?.["stateName"];
      feature.state = countyState?.["stateCode"];
      feature.stateAbbr = county?.["stateAbbr"];
      return {
        GEOID,
        NAME: feature.name,
        ...feature,
      };
    case Geo.METRO:
      const metro = metroMap.find((metro) => +metro?.["CBSA Code"] == +GEOID);
      feature.name = metro?.["CBSA"];
      feature.STATE = metro?.["State Name"];
      feature.state = metro?.["FIPS State Code"];
      feature.stateAbbr = metro?.["State Abbr"];
      return {
        GEOID,
        NAME: feature.name,
        ...feature,
      };
    case Geo.STATE:
      const state = stateMap.find((state) => state?.["stateCode"] == GEOID);

      feature.name = state?.["stateName"];
      feature.STATE = state?.["stateName"];
      feature.state = state?.["stateCode"];
      feature.stateAbbr = state?.["stateAbbr"];
      return {
        GEOID,
        NAME: feature.name,
        ...feature,
      };
    default:
  }
};

export function makeBBoxBigger(box) {
  const factor = 2;
  return {
    x: box.x - box.width * (factor / 2),
    y: box.y - box.height * (factor / 2),
    maxX: box.x + (box.width + box.width * factor),
    maxY: box.y + (box.height + box.height * factor),
  };
}

export function isInBox(obox, point) {
  const box = makeBBoxBigger(obox);
  return (
    box.x <= point.x &&
    box.maxX >= point.x &&
    box.y <= point.y &&
    box.maxY > point.y
  );
}

export const inProximity = (context, inverse = false) => {
  const pointObject = context.view.geometries.find(
    (geometry) => geometry.type === "point",
  );
  const elements = pointObject.elements.map((x) => x.shape.cfg.bbox);
  const currentPoint = context.getCurrentPoint();
  const shouldShow =
    elements.filter((x) => x && isInBox(x, currentPoint)).length > 0;
  return inverse === false ? shouldShow : !shouldShow;
};
